html, body, #root, .fui-FluentProvider {
  height: 100vh;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 599px) {
  .hidden-xs {
      display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .hidden-sm {
      display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .hidden-md {
      display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hidden-lg {
      display: none !important;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .hidden-xl {
      display: none !important;
  }
}

@media (min-width: 2560px) {
  .hidden-xxl {
      display: none !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon {
  grid-area: icon;
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
  height: calc(100vh - 40px);
  overflow: hidden;
}

.main {
  grid-area: main;
  height: calc(100vh - 40px);
  overflow:auto;
  border-left: 1px gray solid;
  border-top: 1px gray solid;
}

.app-container {
  height: 100vh;
  width: 100% !important;
  overflow:hidden;
  display: grid;
  grid-template-columns: 62px auto auto;
  grid-template-rows: 40px auto;
  grid-template-areas: 
    "icon header header"
    "sidebar main main"
}

.twoColumnContainer {
  height: calc(100vh - 42px);
  overflow: hidden;
  display: grid;
  grid-template-columns: 260px auto;
  grid-template-rows: auto;
  grid-template-areas: "nav main";

  .nav {
    grid-area: nav;
    height: 100vh;
  }

  .body {
    grid-area: main;
  }
}

.homePageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 300px auto;
  grid-template-areas: 
    "top"
    "bottom";
  height: calc(100vh - 50px);
  
  .topContainer {
    grid-area: top;

    .cardContainer {
      margin: 20px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    .card {
      width: 300px;
    }
  }

  .bottomContainer {
    grid-area: bottom;
  }
}

.areaPageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 150px auto;
  grid-template-areas: 
    "top"
    "bottom";
  min-height: calc(100vh - 50px);
  
  .topContainer {
    grid-area: top;
  }

  .bottomContainer {
    grid-area: bottom;
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
  }
}

.fadedSection {
  background-color: rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  padding: 5px;
}

@media (max-width: 959px) {
  .actionHeaderContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "actions";
    padding-top: 20px;
    padding-left: 40px;
  
    .title {
      display: none;
    }
  
    .actions {
      align-items: center;
      text-align: center;
      margin: auto;
      grid-area: actions;
    }
  }
}

@media (min-width: 960px) {
  .actionHeaderContainer {
    display: grid;
    grid-template-columns: auto 20%;
    grid-template-rows: auto;
    grid-template-areas: "title actions";
    padding-top: 20px;
    padding-left: 40px;
  
    .title {
      grid-area: title;
      margin-right: 10px;
      max-height: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .actions {
      align-items: center;
      text-align: center;
      margin: auto;
      grid-area: actions;
    }
  }
}
